import { render, staticRenderFns } from "./keywords-table.html?vue&type=template&id=92e006de&scoped=true&"
import script from "./keywords-table.js?vue&type=script&lang=js&"
export * from "./keywords-table.js?vue&type=script&lang=js&"
import style0 from "./keywords-table.scss?vue&type=style&index=0&id=92e006de&prod&scoped=true&lang=scss&"
import style1 from "./keywords-table-global.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e006de",
  null
  
)

export default component.exports