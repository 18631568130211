import { render, staticRenderFns } from "./keywords-form-cancel-confirm.html?vue&type=template&id=a3d8579e&scoped=true&"
import script from "./keywords-form-cancel-confirm.js?vue&type=script&lang=js&"
export * from "./keywords-form-cancel-confirm.js?vue&type=script&lang=js&"
import style0 from "./keywords-form-cancel-confirm.scss?vue&type=style&index=0&id=a3d8579e&prod&scoped=true&lang=scss&"
import style1 from "./keywords-form-cancel-confirm-global.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3d8579e",
  null
  
)

export default component.exports