import Button from "@/components/button";

export default {
    name: "keywords-form-cancel-confirm",
    components: {
        "neo-button": Button,
    },
    props: {
        target: {
            type: String,
            required: true,
        },
        container: {
            type: String,
            required: true,
        },
        customClass: {
            type: String,
            required: false,
        },
        onConfirm: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.focusContent();
            }
        },
    },
    methods: {
        clickConfirm() {
            this.visible = false;
            this.onConfirm();
        },
        focusContent() {
            this.$nextTick(() => {
                this.$refs.focusOn.focus();
            });
        },
    },
};
