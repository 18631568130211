export default function getTableSchema(languages) {
    return [
        {
            label: "Keywords set name",
            field: "keyword_set_name",
            filterOptions: {
                enabled: true,
                placeholder: "Search by name",
                trigger: "enter",
            },
        },
        {
            label: "Languages",
            field: "language",
            filterOptions: {
                enabled: true,
                placeholder: "Select Language",
                filterDropdownItems: languages.map((language) => {
                    return {value: language._id, text: language.name};
                }),
                trigger: "enter",
            },
        },
        {
            label: "Words",
            field: "words",
            formatFn: (words) => {
                if (words.length === 0) {
                    return "-";
                }

                const allWords = words.map((word) => word.word);
                return allWords.join(", ");
            },
            filterOptions: {
                enabled: true,
                placeholder: "Search by words",
                trigger: "enter",
            },
        },
        {
            label: "Navigation",
            field: "navigation",
            sortable: false,
        },
    ];
}
