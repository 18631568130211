export default {
    name: "keywords-word-cell",
    props: {
        words: {
            type: Array,
            required: true,
        },
        displayMaximumWordsCount: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            collapsed: true,
        };
    },
    computed: {
        isLongArray() {
            return this.words.length > this.displayMaximumWordsCount;
        },
        collapseButtonLabel() {
            return this.collapsed ? "show more" : "show less";
        },
    },
    methods: {
        formatLimitedWords(wordsArray) {
            const allWords = wordsArray.map((item) => item.word);
            if (this.isLongArray && this.collapsed) {
                return allWords.splice(0, this.displayMaximumWordsCount).join(", ") + "...";
            }
            return allWords.join(", ");
        },
        toggleShowMore() {
            this.collapsed = !this.collapsed;
        },
    },
};
