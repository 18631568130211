import Button from "@/components/button";
import Input from "@/components/input";

export default {
    name: "table-confirm-delete-keyword",
    components: {
        "neo-button": Button,
        "neo-input": Input,
    },
    props: {
        keywordId: {
            type: String,
            required: true,
        },
        target: {
            type: String,
            required: true,
        },
        container: {
            type: String,
            required: true,
        },
        customClass: {
            type: String,
            required: false,
        },
        onConfirm: {
            type: Function,
            required: true,
        },
        confirmationText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            confirmTextInput: "",
        };
    },
    computed: {
        isConfirmAllowed() {
            return this.confirmationText === this.confirmTextInput;
        },
    },
    methods: {
        clickConfirm() {
            this.visible = false;
            this.onConfirm(this.keywordId);
        },
    },
};
