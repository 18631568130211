import Button from "@/components/button";
import Input from "@/components/input";
import VueMultiselect from "@/components/vue-multiselect";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {uuidv4} from "@/utils/functions";
import {mapGetters} from "vuex";
import {required, minLength} from "vuelidate/lib/validators";
import loader from "@/components/loader";
import KeywordsFormCancelConfirm from "@/views/manage-keywords/keywords-form/keywords-form-cancel-confirm";

export default {
    name: "keywords-form",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "keywords-form-cancel-confirm": KeywordsFormCancelConfirm,
        "vue-multiselect": VueMultiselect,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": loader,
    },
    props: {
        selectedKeywordId: {
            type: String,
        },
        keywordEntityCopy: {
            type: Object,
        },
    },
    data() {
        return {
            keyword_set_name: "",
            language: {},
            words: [],
            loader: false,
        };
    },
    computed: {
        isOnlyOneWord() {
            return this.words.length === 1;
        },
        isEditMode() {
            return this.selectedKeywordId !== "";
        },
        ...mapGetters({
            entities: "getKeywordEntities",
            languages: "getKeywordLanguages",
        }),
    },
    beforeMount() {
        const selectedKeyword = this.selectedKeywordId != null && this.selectedKeywordId !== "" ? this.entities.find((entity) => entity._id === this.selectedKeywordId) : null;
        if (selectedKeyword != null) {
            this.copyKeywordToLocalProps(selectedKeyword);
        } else if (this.keywordEntityCopy != null) {
            this.copyKeywordToLocalProps(this.keywordEntityCopy);
        } else {
            this.words.push(this.createNewWord());
        }
    },
    methods: {
        copyKeywordToLocalProps(keywordEntity) {
            this.keyword_set_name = keywordEntity.keyword_set_name;
            this.language = this.languages.find((language) => language.name === keywordEntity.language);
            this.words = keywordEntity.words.map((word) => {
                return {id: uuidv4(), value: word.word};
            });
        },
        removeWord(id) {
            this.words = this.words.filter((word) => word.id !== id);
        },
        addWord() {
            this.words.push(this.createNewWord());
            this.scrollWordsToTheEnd();
        },
        scrollWordsToTheEnd() {
            this.$nextTick(() => {
                const container = this.$el.querySelector(".keywords-form__multi-inputs");
                container.scrollTop = container.scrollHeight - container.clientHeight;
            });
        },
        createNewWord() {
            return {id: uuidv4(), value: ""};
        },
        async saveNewWord(e) {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const keywordEntity = {
                keyword_set_name: this.keyword_set_name.trim(),
                language: this.language.name,
                words: this.words.map((word) => {
                    return {word: word.value};
                }),
            };

            try {
                this.loader = true;
                if (this.isEditMode) {
                    await this.$store.dispatch("updateKeywordEntity", {
                        id: this.selectedKeywordId,
                        entity: keywordEntity,
                    });
                } else {
                    await this.$store.dispatch("createKeywordEntity", keywordEntity);
                }

                this.$modal.hide("keywords_manage_modal");
            } catch (e) {
                this.loader = false;
                console.error(e);
            }
        },
        closeModal() {
            this.$modal.hide("keywords_manage_modal");
        },
    },
    validations: {
        keyword_set_name: {
            required,
        },
        language: {
            required,
        },
        words: {
            required,
            minLen: minLength(1),
            $each: {
                value: {
                    required,
                },
            },
        },
    },
};
