import PaginationOptions from "@/views/manage-keywords/keywords-table/table.pagination-options.json";
import TableSchema from "@/views/manage-keywords/keywords-table/table.column-schema.js";
import KeywordsWordCell from "@/views/manage-keywords/keywords-table/keywords-word-cell";
import {mapGetters} from "vuex";
import TableConfirmDeleteKeyword from "@/views/manage-keywords/keywords-table/table-confirm-delete-keyword";

export default {
    name: "keywords-table",
    components: {
        "keywords-word-cell": KeywordsWordCell,
        "table-confirm-delete-keyword": TableConfirmDeleteKeyword,
    },
    props: {
        onEditKeyword: {
            type: Function,
            required: true,
        },
        onCopyKeyword: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    beforeMount() {
        this.loadEntities();
    },
    computed: {
        paginationOptions() {
            return {...PaginationOptions, enabled: this.isPaginationEnabled};
        },
        isPaginationEnabled() {
            return this.totalCount > this.perPage;
        },
        columns() {
            return TableSchema(this.languages);
        },
        rows() {
            if (this.entities.length === 0) {
                return [];
            }

            return this.entities;
        },

        ...mapGetters({
            languages: "getKeywordLanguages",
            entities: "getKeywordEntities",
            totalCount: "getTotalKeywordsCount",
            perPage: "getKeywordPerPage",
        }),
    },
    methods: {
        async loadEntities() {
            try {
                this.isLoading = true;
                await this.$store.dispatch("loadKeywordEntities");
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async editEntity(entityId) {
            this.onEditKeyword(entityId);
        },
        async copyEntity(entityId) {
            this.onCopyKeyword(entityId);
        },
        async deleteEntity(entityId) {
            try {
                this.isLoading = true;
                await this.$store.dispatch("deleteKeywordEntity", entityId);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async onPageChange(params) {
            await this.$store.dispatch("setKeywordPage", params.currentPage);
            await this.loadEntities();
        },
        async onPerPageChange(params) {
            await this.$store.dispatch("setKeywordPerPage", params.currentPerPage);
            await this.$store.dispatch("setKeywordPage", 1);
            await this.loadEntities();
        },
        async onColumnFilter(params) {
            const filteredFieldNames = Object.keys(params.columnFilters);
            if (filteredFieldNames.length === 0) {
                return;
            }

            const languageNamesMap = new Map();
            for (let language of this.languages) {
                languageNamesMap.set(language._id, language.name);
            }

            const filters = {};
            for (let filteredFieldName of filteredFieldNames) {
                const filteredValue = params.columnFilters[filteredFieldName];
                if (!filteredValue) {
                    continue;
                }

                if (filteredFieldName === "language") {
                    const languageName = languageNamesMap.get(params.columnFilters[filteredFieldName]);
                    filters[filteredFieldName] = languageName;
                } else {
                    filters[filteredFieldName] = params.columnFilters[filteredFieldName];
                }
            }

            try {
                this.isLoading = true;
                await this.$store.dispatch("setKeywordPage", 1);
                await this.$store.dispatch("setKeywordFilters", filters);
                await this.loadEntities();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async onSortChange(params) {
            try {
                this.isLoading = true;

                await this.$store.dispatch("setKeywordOrder", {
                    ordering_key: params[0].field,
                    ordering_value: params[0].type === "none" ? "ASC" : params[0].type.toUpperCase(),
                });

                await this.loadEntities();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
